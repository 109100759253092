import React, { FC, useState, SyntheticEvent } from 'react';
import styles from './styles.module.scss';
import { Form } from 'layout/components/inputs/form/form';
import { FormField } from 'layout/components/inputs/form/field';
import { Input, InputPhone } from 'layout/components/inputs/input';
import { Checkbox } from 'layout/components/inputs/checkbox';
import { Typo } from 'layout/components/typo/ui';
import { KLK_LINK, LOYALITY_PROGRAM_LINK, PRIVACY_POLICY_LINK } from 'config/commonConstants';
import { State } from '../../../login/model__';
import { useUnit } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
import { model } from '../../model';
import { Link } from '@tanstack/react-router';

interface RegistrationFormProps {
	isModalMode?: boolean;
	toState: (state: State) => void;
}

export const RegistrationForm: FC<RegistrationFormProps> = ({ isModalMode, toState }) => {
	const { form, $pending } = model;
	const [phoneInputIsValid, setPhoneInputIsValid] = useState(false);
	const pending = useUnit($pending);
	const { controller, handleSubmit, setValue } = useForm({ form });
	const { error, isShowError } = useError({
		form,
		name: 'formError',
	});
	const formValues = useUnit(form.$values)

	const titleRegistration = (
		<>
			Регистрация/
			<button
				className={styles.titleButton}
				onClick={() => {
					toState('authorization');
				}}
			>
				Вход
			</button>
		</>
	);

	const onChangePhone = (selection: string, isValid: boolean) => {
		setPhoneInputIsValid(isValid);
		setValue({
			field: 'phone',
			value: selection,
		});
	};

	const handleFormSubmit = (event: SyntheticEvent<HTMLFormElement, Event>) => {
		if (phoneInputIsValid) {
			handleSubmit(event);
		}
	};

	return (
		<div>
			<Form
				handleSubmit={handleFormSubmit}
				error={error}
				isShowError={isShowError}
				title={isModalMode ? titleRegistration : 'Регистрация'}
				text={
					!isModalMode
						? 'Укажите данные для регистрации и входа'
						: 'Для получения максимальной выгоды при бронировании - укажите свои данные:'
				}
				isDisabled={
					!formValues.agreeProcessData
					|| !formValues.agreeKlk
					|| !formValues.agreeLoyalityProgram
					|| !formValues.phone?.length
					|| !formValues.firstName?.length
				}
				buttonContent={isModalMode ? 'Продолжить' : 'Зарегистрироваться'}
				pending={pending}
			>
				<FormField
					mask="+###################"
					placeholder="Телефон"
					required
					size="lg"
					withLabel
					controller={controller}
					form={form}
					name="phone"
					secondPlaceholder="+70000000000"
					masked
					validation={{
						validateType: 'phone',
						required: true,
					}}
				>
					<InputPhone onNumberChange={onChangePhone} />
				</FormField>
				<FormField
					maxLength={250}
					placeholder="Фамилия"
					required={!isModalMode}
					size="lg"
					withLabel
					name="lastName"
					controller={controller}
					form={form}
					validation={{ required: !isModalMode }}
				>
					<Input />
				</FormField>
				<FormField
					maxLength={250}
					placeholder="Имя"
					required
					size="lg"
					withLabel
					name="firstName"
					controller={controller}
					form={form}
					validation={{ required: true }}
				>
					<Input />
				</FormField>
				<FormField
					placeholder="Отчество"
					required={!isModalMode}
					size="lg"
					withLabel
					controller={controller}
					form={form}
					name="middleName"
					validation={{
						required: !isModalMode,
					}}
				>
					<Input />
				</FormField>
				<FormField controller={controller} form={form} name="agreeProcessData">
					<Checkbox defaultSelected>
						Я даю согласие на обработку{' '}
						<a href={PRIVACY_POLICY_LINK} target="_blank">
							<Typo className={styles.link} as="a" target="_blank" design="description-m">
								Персональных данных
							</Typo>
						</a>
					</Checkbox>
				</FormField>
				{/* <FormField controller={controller} form={form} name="agreeSendMessages"> */}
				<FormField controller={controller} form={form} name="agreeKlk">
					<Checkbox defaultSelected>
						{/* Я согласен получать уведомления, информационные сообщения и материалы рекламного 
							характера
							*/}
						Я даю согласие на вступление в Клуб Любителей Круизов, что дает мне право получать и использовать бонусные баллы.{' '}
						<a href={KLK_LINK} target="_blank">
							<Typo className={styles.link} as="a" target="_blank" design="description-m">
								Подробнее о Клубе
							</Typo>
						</a>
					</Checkbox>
				</FormField>

				{/* <FormField controller={controller} form={form} name="agreeSendMessages"> */}
				<FormField controller={controller} form={form} name="agreeLoyalityProgram">
					<Checkbox defaultSelected>
						Я даю согласие на вступление{' '}
						<a href={LOYALITY_PROGRAM_LINK} target="_blank">
							<Typo className={styles.link} as="a" target="_blank" design="description-m">
								Программу лояльности "Созвездие"
							</Typo>
						</a>

					</Checkbox>
				</FormField>

			</Form>
		</div>
	);
};
