/* eslint-disable complexity */
import { combineEvents, and } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
// import { guestDataModel } from 'widgets/order/order-customer/model';
// import { profilatorModel } from 'features/load-profilator-data';
import { bonusCardModel } from 'entities/bonus-card';
import { cartModel } from 'entities/cart';
import { orderCabinsModel, requestModel, userRoleModel } from 'entities/order';
import { successOrderModalModel } from 'entities/order/success-modal';
import { message } from 'config/constants/message';
import { CUSTOMER_REQUIRED } from 'config/constants/order';
import { CreateRequestRequestCabinsInner, CreateRequestRequestBonusPaymentTypeEnum } from 'config/types/prices';
import { profilatorModel } from 'layout/components/auth/load-profilator-data';
import { profileModel, parsePassportData } from 'layout/components/auth/profile';
import { AccountRoutes } from 'store/auth/constants';
import { routerModel } from 'utils/router';
import { verificationModel } from 'utils/verification';
import { guestDataModel } from '../../order-customer/model';
import { formWithSessionStorageFactory, promoSessionStorageFactory, trimFactory } from 'utils/form';
// import { parsePassportData, profileModel } from 'entities/profile';
// import { verificationModel } from 'entities/verification';
// import { CUSTOMER_REQUIRED, AccountRoutes } from 'shared/config';
// import { message } from 'shared/lib/message';
// import {
// 	CreateRequestRequestBonusPaymentTypeEnum,
// 	CreateRequestRequestCabinsInner,
// } from 'shared/lib/types/prices';
// import {
// 	formWithSessionStorageFactory,
// 	promoSessionStorageFactory,
// 	trimFactory,
// } from 'shared/lib/utils';
// import { routerModel } from 'shared/model';

const Gate = createGate();
const LeadGate = createGate();

const $setInitialValues = and(LeadGate.status, profileModel.$profile);
/* *
 * Форма
 */

interface ConfirmationFormValues {
	comment: string;
	bonusPaymentCount?: number;
	bonusPaymentType?: string;
	promocode?: string;
}

export interface GuestFormValues {
	phone: string;
	firstName: string;
	lastName: string;
	middleName: string;
}

interface GuestValues extends GuestFormValues {
	email?: string;
	officeId?: string;
	birthday?: string;
	citizenship?: string;
	gender?: string;
	referrer?: number;
	captcha?: Nullable<string>;
	example?: string;
	documentType?: string;
	passportSeries?: string;
	passportNumber?: string;
	isCallCenter?: string;
}

const $bitrixLeadId = createStore<Nullable<number | undefined>>(null);

const $setCloseModal = createStore<boolean>(false);

const parseBitrixLeadId = () => JSON.parse(sessionStorage.getItem('bitrixLeadId') || 'null');

const resetBitrixLeadIdSS = createEvent();
const resetBitrixLeadIdSSFx = createEffect(() => {
	sessionStorage.removeItem('bitrixLeadId');
});

const loadBitrixLeadIdFX = createEffect(parseBitrixLeadId);
const loadBitrixLeadIdSS = createEvent();

sample({
	clock: cartModel.Gate.open,
	target: [loadBitrixLeadIdSS, profilatorModel.loadProfilatorSS],
});

sample({
	clock: [requestModel.requestFx.doneData, cartModel.reset, cartModel.resetWithDelay],
	target: [resetBitrixLeadIdSS, guestDataModel.resetCustomerFormSS],
});

sample({
	clock: resetBitrixLeadIdSS,
	target: resetBitrixLeadIdSSFx,
});

sample({
	clock: loadBitrixLeadIdSS,
	target: loadBitrixLeadIdFX,
});

sample({
	clock: loadBitrixLeadIdFX.doneData,
	target: $bitrixLeadId,
});

sample({
	clock: LeadGate.open,
	target: requestModel.requestBitrix,
});

const syncbitrixLeadIdSSFx = createEffect((bitrixLeadId: Nullable<number | undefined>) => {
	sessionStorage.setItem('bitrixLeadId', JSON.stringify(bitrixLeadId));
});

sample({
	clock: Gate.open,
	target: guestDataModel.loadCustomerFormSS,
});

sample({
	source: $bitrixLeadId,
	target: syncbitrixLeadIdSSFx,
});

const form = createForm<ConfirmationFormValues>({
	initialValues: {
		comment: '',
	},
	validate: ({ values }) => {
		const errors: Partial<Record<string, string>> = {};

		if (values.bonusPaymentCount && !values.bonusPaymentType) {
			errors.bonusPaymentType = message.form.validation.required;
		}
		if (!values.bonusPaymentCount && values.bonusPaymentType) {
			errors.bonusPaymentCount = message.form.validation.required;
		}

		return errors;
	},
});

/* *
 * Session storage
 */
formWithSessionStorageFactory({
	form,
	parseEvent: [Gate.open],
	resetEvent: [requestModel.requestFx.doneData],
	name: 'confirmationFormData',
});

/* *
 * Триминг
 */
trimFactory(form, ['comment']);

/* *
 * Бонусы и промо-код
 */
sample({
	clock: combineEvents({ events: [Gate.open, profileModel.$profile.updates] }),
	source: profileModel.$profile,
	filter: (profile) => Boolean(profile),
	target: bonusCardModel.getBonusCard,
});

const fieldFactory = <T = any>(field: string, initial?: T, withConfirmation = true) => {
	const reset = createEvent();
	const $value = createStore<Nullable<T>>(initial ?? null).reset(reset);
	const setValue = createEvent<Nullable<T>>();
	const confirmValue = createEvent();

	sample({
		clock: setValue,
		target: $value,
	});

	if (!withConfirmation) {
		sample({
			clock: $value,
			target: confirmValue,
		});
	}

	sample({
		source: $value,
		clock: confirmValue,
		fn: (value) => ({
			field,
			value,
		}),
		target: form.setValue,
	});

	return {
		$value,
		setValue,
		confirmValue,
		reset,
	};
};

const bonusPaymentCount = fieldFactory<number>('bonusPaymentCount', 0);
const bonusPaymentType = fieldFactory<Nullable<'cabin' | 'place'>>('bonusPaymentType', null, false);
const cardNumber = fieldFactory<string>('inviteCard', '');
const promocode = fieldFactory<string>('promocode', '');
const closeModal = createEvent();

promoSessionStorageFactory({
	form,
	factory: promocode,
	parseEvent: [Gate.open],
	resetEvent: [requestModel.requestFx.doneData, cartModel.reset, cartModel.resetWithDelay],
	name: 'promocode',
});
promoSessionStorageFactory({
	form,
	factory: cardNumber,
	parseEvent: [Gate.open],
	resetEvent: [requestModel.requestFx.doneData, cartModel.reset, cartModel.resetWithDelay],
	name: 'inviteCard',
});

promoSessionStorageFactory({
	form,
	factory: bonusPaymentCount,
	parseEvent: [Gate.open],
	resetEvent: [requestModel.requestFx.doneData, cartModel.reset, cartModel.resetWithDelay],
	name: 'bonusPaymentCount',
});

promoSessionStorageFactory({
	form,
	factory: bonusPaymentType,
	parseEvent: [Gate.open],
	resetEvent: [requestModel.requestFx.doneData, cartModel.reset, cartModel.resetWithDelay],
	name: 'bonusPaymentType',
});

sample({
	clock: requestModel.request,
	target: form.submit,
});

sample({
	source: {
		cruiseId: cartModel.$activeCruise,
		bitrixLeadId: $bitrixLeadId,
		cabins: orderCabinsModel.$cabins,
		isGuest: userRoleModel.$isGuest,
		guest: guestDataModel.$prepearedCustomerData,
		captcha: verificationModel.$captchaToken,
		promocode: promocode.$value,
		cardNumber: cardNumber.$value,
		bonusPaymentType: bonusPaymentType.$value,
		form: form.$values,
		formErrors: form.$errorsInline,
		profilator: profilatorModel.$profilator,
	},
	filter: (source) =>
		source.cruiseId !== null &&
		source.cruiseId !== undefined &&
		Object.keys(source.formErrors).length === 0,
	clock: requestModel.request,
	fn: (source) => {
		let guest = {};
		const customerRequired = source.isGuest || CUSTOMER_REQUIRED;

		if (customerRequired && source.guest) {
			guest = {
				officeId: source.guest.officeId || undefined,
				phone: source.guest.phone.replace('+', '') || undefined,
				email: source.guest.email || undefined,
				firstName: source.guest.firstName || undefined,
				lastName: source.guest.lastName || undefined,
				middleName: source.guest.middleName || undefined,
				birthday: source.guest.birthday || undefined,
				citizenship: source.guest.citizenship || undefined,
				gender: source.guest.gender || undefined,
				...(source.guest.documentData ? parsePassportData(source.guest.documentData) : {}),
				referrer: source.guest.referrer || undefined,
				captcha: source.captcha,
				building: source.guest.building || undefined,
				buildingNumber: source.guest.buildingNumber || undefined,
				city: source.guest.city || undefined,
				country: source.guest.country || undefined,
				district: source.guest.district || undefined,
				apartment: source.guest.office || undefined,
				raw: source.guest.raw || undefined,
				region: source.guest.region || undefined,
				street: source.guest.street || undefined,
				zip: source.guest.zip || undefined,
				example: '5',
			};
		}

		return {
			cruiseId: source.cruiseId as number,
			bitrixLeadId: source.bitrixLeadId as number,
			cabins: source.cabins as CreateRequestRequestCabinsInner[],
			promocode: source.promocode || undefined,
			inviteCard: source.cardNumber || undefined,
			bonusPaymentType:
				(source.bonusPaymentType as CreateRequestRequestBonusPaymentTypeEnum) || undefined,
			bonusPaymentCount: source.form.bonusPaymentCount || undefined,
			comment: source.form.comment || undefined,
			UTM: source.profilator || undefined,
			...guest,
		};
	},
	target: requestModel.requestFx,
});

sample({
	source: {
		cruiseId: cartModel.$activeCruise,
		cabins: orderCabinsModel.$cabins,
		undatedCabins: cartModel.$updatedCabins,
		isGuest: userRoleModel.$isGuest,
		guest: guestDataModel.$prepearedCustomerBitrixData,
		captcha: verificationModel.$captchaToken,
		promocode: promocode.$value,
		cardNumber: cardNumber.$value,
		profile: profileModel.$profile,
		bonusPaymentType: bonusPaymentType.$value,
		form: form.$values,
		formErrors: form.$errorsInline,
		bitrixLeadId: $bitrixLeadId,
		profilator: profilatorModel.$profilator,
	},
	filter: (source) =>
		source.cruiseId !== null &&
		source.cruiseId !== undefined &&
		Object.keys(source.formErrors).length === 0,
	clock: requestModel.requestBitrix,

	fn: (source) => {
		let guest = {};

		const customerRequired = source.isGuest || CUSTOMER_REQUIRED;

		if (customerRequired && source.guest) {
			guest = {
				officeId: source.guest.officeId || undefined,
				phone: source.guest.phone.replace('+', '') || undefined,
				email: source.guest.email || undefined,
				firstName: source.guest.firstName || undefined,
				lastName: source.guest.lastName || undefined,
				middleName: source.guest.middleName || undefined,
				birthday: source.guest.birthday || undefined,
				citizenship: source.guest.citizenship || undefined,
				gender: source.guest.gender || undefined,
				...(source.guest.documentData ? parsePassportData(source.guest.documentData) : {}),
				referrer: source.guest.referrer || undefined,
				captcha: source.captcha,
				example: '5',
			} as GuestValues;
		} else if (source.profile) {
			guest = {
				phone: source.profile?.phone.replace('+', '') || undefined,
				firstName: source.profile?.passportData.firstName || undefined,
				lastName: source.profile?.passportData.lastName || undefined,
				middleName: source.profile?.passportData.middleName || undefined,
				email: source.profile?.email || undefined,
			};
		}

		return {
			cruiseId: source.cruiseId as number,
			bitrixLeadId: source.bitrixLeadId as number,
			cabins: source.undatedCabins.body.cabins as CreateRequestRequestCabinsInner[],
			promocode: source.promocode || undefined,
			inviteCard: source.cardNumber || undefined,
			bonusPaymentType:
				(source.bonusPaymentType as CreateRequestRequestBonusPaymentTypeEnum) || undefined,
			bonusPaymentCount: source.form.bonusPaymentCount || undefined,
			comment: source.form.comment || undefined,
			UTM: source.profilator || undefined,
			...guest,
		};
	},
	target: requestModel.requestBitrixFx,
});

sample({
	clock: requestModel.requestBitrixFx.doneData,
	filter: (response) => !!response.bitrixLeadId,
	fn: (response) => response.bitrixLeadId,
	target: $bitrixLeadId,
});

const showSuccessModal = combineEvents({
	events: [
		requestModel.requestFx.doneData,
		requestModel.$requestResponse.updates,
		successOrderModalModel.ModalGate.open,
	],
});

sample({
	clock: showSuccessModal,
	target: successOrderModalModel.openModal,
});

sample({
	clock: closeModal,
	source: userRoleModel.$isGuest,
	// fn: (isGuest) => (isGuest ? '/' : AccountRoutes.MyRequests),
	fn: (isGuest) => (isGuest ? '/' : AccountRoutes.Profile),
	// target: [routerModel.push, successOrderModalModel.closeModal, cartModel.reset],
	target: [routerModel.pushAsA, successOrderModalModel.closeModal, cartModel.reset],
});

sample({
	clock: Gate.close,
	fn: () => null,
	target: requestModel.$requestError,
});

export const model = {
	Gate,
	LeadGate,
	$setCloseModal,
	form,
	bonusPaymentCount,
	bonusPaymentType,
	promocode,
	cardNumber,
	closeModal,
	$setInitialValues,
};
