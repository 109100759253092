import React, { useState, SyntheticEvent } from 'react';
import styles from './styles.module.scss';
import { Form } from 'layout/components/inputs/form/form';
import { emailMask } from 'layout/components/inputs/form/masks';
import { Tab, Tabs } from 'layout/components/switchers/tabs';
import { FormField } from 'layout/components/inputs/form/field';
import { model } from './model';
import { Message } from 'config/commonTypes';
import { Input, InputPhone } from 'layout/components/inputs/input';
import { InputPassword } from 'layout/components/inputs/input/input-password';
import { useError, useForm } from 'effector-react-form';
import { useUnit } from 'effector-react';
import { ValidationOptions } from 'layout/components/inputs/form/lib/validation';
import { Button } from 'layout/components/buttons/button/button';


interface AuthorizationFormProps {
	isResetPassword?: boolean;
	recoverPassword: () => void;
	isModalMode?: boolean;
}

export const AuthorizationForm = ({
	recoverPassword,
	isResetPassword = false,
	isModalMode,
}: AuthorizationFormProps) => {
	const { form, $pending, $phoneCodePending } = model;

	const [phoneInputIsValid, setPhoneInputIsValid] = useState<boolean>(false);
	// const [pending, setPending] = useState<boolean>(false);
	// const [phoneCodePending, setPhoneCodePending] = useState<boolean>(false);
	// const [isShowError, setIsShowError] = useState<boolean>(false);
	// const [error, setError] = useState<Message>("");
	const [pending, phoneCodePending] = useUnit([$pending, $phoneCodePending]);
	const { controller, handleSubmit } = useForm({ form });

	const [setValidation, setValidationPassword] = useUnit([
		model.setValidation,
		model.setValidationPassword,
	]);

	const { error, isShowError } = useError({
		form,
		name: 'formError',
	});

	const [setValue] = useUnit([form.setValue]);
	const [selectedTab, setSelectedTab] = useState(0);

	const loginProps = [
		{
			placeholder: 'Телефон',
			secondPlaceholder: 'Номер телефона',
			name: 'login',
			validation: {
				required: true,
			},
		},
		{
			placeholder: 'Email',
			secondPlaceholder: 'user@mail.com',
			name: 'login',
			validation: {
				validateType: 'email',
				required: true,
			},
			maxLength: 250,
			...emailMask,
		},
		{
			placeholder: 'Логин или номер КЛК',
			secondPlaceholder: 'Логин или номер',
			name: 'login',
			validation: {
				required: true,
			},
		},
	];

	const handleRecoverPassword = () => {
		recoverPassword();
	};

	const onChangePhone = (selection: string, isValid: boolean) => {
		setPhoneInputIsValid(isValid);
		setValue({
			field: 'login',
			value: selection,
		});
	};

	const handleFormSubmit = (event: SyntheticEvent<HTMLFormElement, Event>) => {
		if (phoneInputIsValid || selectedTab !== 0) {
			handleSubmit(event);
		}
	};

	return (
		<Form
			footer={
				isResetPassword || selectedTab === 0 ? undefined : (
					<Button onClick={handleRecoverPassword} btnType="text" size="large">
						Забыли пароль?
					</Button>
				)
			}
			handleSubmit={handleFormSubmit}
			isShowError={isShowError}
			error={error}
			title={isResetPassword ? 'Пароль изменен' : 'Вход'}
			text={
				isResetPassword
					? 'Вы успешно изменили свой пароль. Войдите в систему Созвездие с новым паролем'
					: undefined
			}
			buttonContent={selectedTab === 0 ? 'Отправить код' : isModalMode ? 'Продолжить' : 'Вход'}
			pending={selectedTab !== 0 ? pending : phoneCodePending}
		>
			<Tabs
				className={styles.tabs}
				handleChangeTab={(index) => {
					setSelectedTab(index);
					setValue({
						field: 'login',
						value: '',
					});

					if (index === 0) {
						setValidationPassword({ validation: { required: false }, name: 'password' });
						setValue({
							field: 'password',
							value: '',
						});
					}

					if (loginProps[selectedTab].validation) {
						setValidation({
							validation: loginProps[index].validation as ValidationOptions,
							name: loginProps[index].name as string,
						});
					}
				}}
				theme="dashed"
			>
				<Tab
					label="Телефон"
					key="phone"
					id="phone"
				// analyticsTarget={ANALYTICS_TARGETS.signin.signin_tab_phone}
				/>
				<Tab
					label="Почта"
					key="email"
					id="email"
				// analyticsTarget={ANALYTICS_TARGETS.signin.signin_tab_email}
				/>
				<Tab
					label="Логин или номер КЛК"
					key="klk"
					id="klk"
					// analyticsTarget={ANALYTICS_TARGETS.signin.signin_tab_login}
				/>
			</Tabs>

			<div>
				<FormField
					form={form}
					controller={controller}
					required
					withLabel={selectedTab !== 0}
					size="lg"
					{...loginProps[selectedTab]}
				>
					{selectedTab !== 0 ? <Input /> : <InputPhone onNumberChange={onChangePhone} />}
				</FormField>
			</div>
			{selectedTab !== 0 && (
				<FormField
					placeholder="Пароль"
					secondPlaceholder="•••••••••"
					controller={controller}
					mask="a"
					maskOptions={{
						tokens: {
							a: {
								pattern: /[^ ]/,
								multiple: true,
							},
						},
					}}
					form={form}
					required={selectedTab !== 0}
					validation={selectedTab === 0 ? null : { required: true }}
					name="password"
					size="lg"
					withLabel
				>
					<InputPassword />
				</FormField>
			)}
		</Form>
	);
};
